<template>
  <list-template
      :search-config="searchConfig"
      @onSearch="onSearch"
      :table-config="tableConfig"
      :table-data="tableData"
      :total="total"
      :loading="loading"
      :current-page="page"
      :isDownload="true"
      :downloadDisabled="downloadDisabled"
      @onExportData="onDownload"
      @onChangePage="handleCurrentChange"
      @onHandle="handleClick"
			:isCheckInputBox="false"
			@onChange="onChange"
      @onExportList="onExportList"
	></list-template>
</template>
<script>
import {mapState} from 'vuex'
import tools from "@/utils/tools";

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  name: 'List',
  computed: {
    ...mapState(['page']),
    downloadDisabled: function () {
      let boo = true
      Object.keys(this.sessionFormData).forEach(key => {
        if (this.sessionFormData[key]) boo = false
      })
      return boo
    }
  },
	watch: {
		testOp: {
			handler(val) {
				this.searchConfig[1].options = val
				this.searchConfig[1].default = val[0].time
				document.querySelectorAll('button').forEach(item => {
					if (item.querySelector('span') && item.querySelector('span').textContent === '重置') {
						item.click()
					}
				})
			},
			deep: true
		}
	},
  created() {
    this.$store.commit('setPage', 1)
    this.getSchool()
    this.getGrade()
    this.getTime()
  },
  methods: {
    onDownload() {
      this.getData(1)
    },
    onExportList() {
      this.getData(2)
    },

    getTime() {
      this.$_axios2('api/teacher/moral-educationController/pc-cycle').then(res => {
        let arr = res.data.data
        if (arr.length) {
					this.testOp = arr.map(item => {
            return {
              time: item.day_start + ' - ' + item.day_end
            }
          })
        } else {
					this.testOp = []
        }
      })
    },

    getData(type = 0) {
      let params;
      if (type) {
        params = {
          page: this.page,
          ...this.sessionFormData,
          day_start: this.sessionFormData.month ? this.sessionFormData.month.split('-')[0] : '',
          day_end: this.sessionFormData.month ? this.sessionFormData.month.split('-')[1] : '',
        }
        params.type = type
        delete params.month
      } else {
        params = {
          page: this.page,
          ...this.search,
          day_start: this.search.month ? this.search.month.split('-')[0] : '',
          day_end: this.search.month ? this.search.month.split('-')[1] : '',
        }
        delete params.month
      }
      this.loading = true
      this.$_axios2
					.get('/api/teacher/moral-educationController/pc-list', {params, timeout: 1e2 * 60 * 1000})
          .then(res => {
            if (type) {
              tools.download('德育一对一辅导记录', res.data)
            } else {
              const {
                data: {
                  data: {list, page}
                }
              } = res
              this.total = page.total
              this.pageCount = page.last_page
              this.tableData = list
              this.loading = false
            }
          })
          .finally(() => {
            this.loading = false
            if (type === 0) {
              this.sessionFormData = JSON.parse(JSON.stringify(this.search))
            }
          })
    },
    getSchool() {
      this.$_axios.get('/site/school').then(res => {
        let {data} = res.data
        if (data.length > 0) {
          data.unshift({
            id: '0',
            isSelected: false,
            school_name: '全部'
          })
          this.searchConfig[3].options = data
        }
      })
    },
    getGrade() {
      this.$_axios.get('/site/grades').then(res => {
        let {data} = res.data
        if (data.length > 0) {
          data.unshift({
            id: '0',
            isSelected: false,
            name: '全部'
          })
          this.searchConfig[4].options = data
        } else {
          this.searchConfig[4].options = []
        }
      })
    },
    onSearch(val) {
      this.$store.commit('setPage', 1)
      this.search = val
      this.getData()
    },
    onChange(val) {
      this.search = val
    },
    handleClick(row) {
      this.$router.push('./details?id=' + row.id)
    },
    handleCurrentChange(val) {
      this.$store.commit('setPage', val)
      this.getData()
		}
  },
  data() {
    return {
      search: {
        teacher_name: '',
        month: '',
        status: '',
        school_id: '',
        grade_id: '',
      },
      sessionFormData: {
        teacher_name: '',
        month: '',
        status: '',
        school_id: '',
        grade_id: '',
      },  //该数据用于存放搜索后的搜索条件，然后导出
      tableData: [],
      loading: false,
      //总条数
      total: 0,
      pageCount: 0,
			testOp: [],
      searchConfig: [
        {
          tag: 'input',
          prop: 'teacher_name',
          placeholder: '搜索教师姓名'
        },
        {
          tag: 'select',
          placeholder: '筛选周期',
          prop: 'month',
					options: this.testOp,
          label: 'time',
          value: 'time',
					default: '',
        },
        {
          tag: 'select',
          placeholder: '筛选是否完成',
          prop: 'status',
          options: [
            // { name: '全部', id: '' },
            {name: '完成', id: 1},
            {name: '未完成', id: 0}
          ],
          label: 'name',
          value: 'id'
        },
        {
          tag: 'select',
          placeholder: '筛选校区',
          prop: 'school_id',
          options: [],
          label: 'school_name',
          value: 'id',
          change: (val, search) => {
            this.search = search
            if (search.grade_id) this.search.grade_id = ''
          }
        },
        {
          tag: 'select',
          placeholder: '筛选年级',
          prop: 'grade_id',
          options: [],
          label: 'name',
          value: 'id'
        }
      ],
      tableConfig: [
        {
          prop: 'teacher_name',
          label: '班主任姓名'
        },
        {
          prop: 'day_describe',
          label: '起始日期',
        },
        {
          prop: 'status',
          label: '是否全部完成',
          render: row => {
            return row.status ? '已完成 ' : '未完成'
          }
        },
        {
          prop: 'complete_rate',
          label: '完成率',
          render: row => row.complete_rate + '%'
        },
        {
          prop: 'avg_coach_content',
          label: '辅导平均字数'
        },
        {
          prop: 'class_name',
          label: '行政班'
        },
        {
          prop: 'grade_name',
          label: '年级'
        },
        {
          prop: 'school_name',
          label: '校区'
        },
        {
          prop: 'handle',
          label: '操作',
          width: '120rem',
          handle: true,
          buttons: [{ type:"view", text:"查看" }]
        }
      ]
    }
  }
}
</script>
<style></style>
